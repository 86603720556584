import React from 'react'
import { LinkedInIcon, TwitterIcon, EmailIcon, ArrowLeftShort } from './Icons'
import SanityImage from './SanityImage'
import { useImageSize } from '../hooks/useImageSize'

export const PersonModal = ({ name, personDescription, personLinks, _rawPhoto, photo, position, hideModal }) => {
	const imageSize = useImageSize(0.9)
	const handleClose = e => {
		e.preventDefault()
		hideModal()
	}

	return (
		<div className='px-10 pt-14 pb-6 relative bg-white w-screen h-screen overflow-hidden'>
			<div className='max-w-sm mx-auto flex flex-col h-full'>
				<h3 className='h3 whitespace-nowrap pb-2'>meet our team</h3>
				<div className='relative w-full overflow-hidden team-aspect z-10'>
					<img
						src={photo?.asset?.metadata?.preview}
						alt={photo?.alt || ''}
						className='absolute w-full inset-0 object-contain -z-10'
					/>
					<SanityImage
						image={_rawPhoto}
						maxWidth={imageSize}
						className='object-cover mx-auto max-h-[360px] w-full h-full z-50'
						loading='lazy'
					/>
				</div>
				<div className='pt-4'>
					<p className='h6'>{name}</p>
					<p className='text-20 font-display uppercase leading-none'>{position}</p>
					{personLinks && <span className='flex space-x-5 items-end'>
						{personLinks?.email &&
							<a
								href={`mailto:${personLinks.email}`}
								target='_blank'
								rel='noreferrer'
							>
								<EmailIcon className='w-4' />
							</a>}
						{personLinks?.linkedIn &&
							<a
								href={personLinks.linkedIn}
								target='_blank'
								rel='noreferrer'
							>
								<LinkedInIcon className='w-4 pb-[2px]' />
							</a>}
						{personLinks?.twitter &&
							<a
								href={personLinks.twitter}
								target='_blank'
								rel='noreferrer'
							>
								<TwitterIcon className='w-4' />
							</a>}
					</span>}
				</div>
				<p className='body-xs uppercase pt-4'>{personDescription}</p>
				<button
					className='mt-auto mb-10'
					aria-label='Close Modal'
					onClick={handleClose}
				>
					<ArrowLeftShort className='' />
				</button>
			</div>
		</div>
	)
}
