import React from 'react'
import { Link } from 'gatsby'
import ModalButton from './ModalButton'
import { useSocial } from '../hooks/useSocial'

const Footer = () => {
  const { socialLinks } = useSocial()

  const aboutLinks = [
    { name: 'Faq', link: '/how#faq' },
    { name: 'Blog', link: '/blog' },
    { name: 'Press Kit', link: '/press' },
  ]

  return (
    <footer className="bg-white text-black pt-[5%]">
      <div className="border-y-4 pb-8 pt-4 sm:pb-4 md:py-3 relative">
        <div className="flex mx-auto nav-container--footer">
          <div className="hidden md:block w-[40%] flex-1">
            <img
              src="/Emblem.svg"
              width={160}
              height={244}
              sizes={`(max-width: 1920px) 160px`}
              className="-mb-3 w-[15vw] py-[1%] md:max-h-64 md:max-w-min h-auto"
              alt=""
            />
          </div>
          <div className="flex w-full md:w-auto">
            <div className="flex w-4/5 justify-around md:w-full my-auto md:space-x-4vw 2xl:space-x-6vw">
              <ul className="h6">
                <h5 className="h2-alt--footer">about</h5>
                {aboutLinks.map(({ name, link }, index) => (
                  <li
                    className="my-3 md:my-[8%] last:mb-0 ml-2 whitespace-nowrap md:ml-4 lg:ml-8"
                    key={index}
                  >
                    <h6 className="h6">
                      <Link to={link}>{name}</Link>
                    </h6>
                  </li>
                ))}
                <ModalButton location={''} type={'contact'} />
              </ul>
              <ul className="h6">
                <h5 className="h2-alt--footer">social</h5>
                {socialLinks?.map(({ _key, blank, title, link }) => (
                  <li
                    key={_key}
                    className="my-3 md:my-[8%] last:mb-0 ml-2 whitespace-nowrap md:ml-4 lg:ml-8"
                  >
                    {blank ? (
                      <h6 className="h6">
                        <a
                          href={link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {title}
                        </a>
                      </h6>
                    ) : (
                      <a href={link}>{title}</a>
                    )}
                  </li>
                ))}
              </ul>
              <ModalButton location={'footer'} type={'signUp'} />
            </div>
          </div>
        </div>
      </div>
      <div className="display-xs mx-5 my-[1%] md:my-[0.5%] uppercase md:text-right">
        <span>
          Copyright &copy; {new Date().getFullYear()} All rights reserved.
        </span>
      </div>
    </footer>
  )
}

export default Footer
