export function mappable(size) {
	return new Array(size).fill(0).map((_, i) => i);
}

export const splitList = (array) => {
	const halfWayIndex = Math.ceil(array.length / 2)
	const first = array.slice(0, halfWayIndex)
	const second = array.slice(halfWayIndex)
	return [first, second]
}

export function fillArray(size, content = 0) {
	return new Array(size).fill(content);
}

export const isOdd = (index) => index % 2 !== 0

export const splitTitle = (title) => {
	const half = Math.floor(title?.split(' ').length / 2)
	let top = [];
	let bottom = [];

	if (title) {
		title.split(' ').forEach((word, index) => {
			index < half ? top.push(word) : bottom.push(word)
		})
	}

	top = top.join(' ')
	bottom = bottom.join(' ')

	return { top, bottom }
}

export const shortenString = (str) => {
	const length = str.length
	return length > 160 ? `${str.slice(0, 160)}...` : str
}

export const getDateString = (date) => {
	const dateString = new Date(date)
	return dateString.toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric" })
}

export const flattenBlogs = (array) => {
	const resultObj = {}

	array.forEach((item, index) => {
		const key = item?.slug?.current ? item.slug.current : item.node.slug.current
		resultObj[key] = item
		resultObj[key]['index'] = index
	})
	return resultObj
}

export const getListSegments = (array) => {
	const length = array?.length
	const segment = +parseFloat(1 / length).toFixed(2)
	const listSegments = []
	let tempValue = segment
	let index = 0

	while (tempValue <= 1) {
		listSegments.push([+parseFloat(tempValue).toFixed(2), index])
		tempValue += segment
		++index
	}
	return listSegments
}

export const getCardIndex = (scrollYPercent, array, setRange) => {
	for (let i = 0; i < array.length; i++) {
		if (scrollYPercent <= array[i][0]) {
			if (setRange) {
				setRange([(array[i - 1] && array[i - 1][0]) || 0, array[i][0]])
			}
			return array[i][1]
		}
	}
}

export function getImageDimensions(image) {
	if (!image?.asset?._ref) {
		return
	}

	const dimensions = image.asset._ref.split('-')[2]
	const [width, height] = dimensions.split('x').map(Number)

	if (!width || !height || Number.isNaN(width) || Number.isNaN(height)) {
		return
	}

	return {
		width,
		height,
		aspectRatio: width / height,
	}
}
