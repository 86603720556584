import { getImageDimensions } from './index'
import imageUrlBuilder from '@sanity/image-url'
import clientConfig from '../../client'

export const imageBuilder = imageUrlBuilder(clientConfig.sanity)

const LARGEST_VIEWPORT = 1920

const DEFAULT_MIN_STEP = 0.1 // 10%
const DEFAULT_WIDTH_STEPS = [400, 600, 850, 1000, 1150] // arbitrary
// Based on statcounter's most common screen sizes: https://gs.statcounter.com/screen-resolution-stats
const DEFAULT_FULL_WIDTH_STEPS = [360, 414, 768, 1366, 1536, 1920]

export default function getImageProps({
  image,
  maxWidth: userMaxWidth,
  minimumWidthStep = DEFAULT_MIN_STEP,
  customWidthSteps,
  sizes,
}) {

  if (!image?.asset?._ref) {
    return {}
  }
  const maxWidth =
    typeof userMaxWidth === 'number' ? userMaxWidth : LARGEST_VIEWPORT
  const builder = imageBuilder.image(image).fit('max').auto('format')
  const imageDimensions = getImageDimensions(image)

	customWidthSteps = userMaxWidth ? [0.5, 0.75, 1, 1.5, 2].map(num => Math.ceil(num * userMaxWidth)) : null

  const baseSizes = [
    maxWidth,
    ...(customWidthSteps ||
      (typeof userMaxWidth === 'number'
        ? DEFAULT_WIDTH_STEPS
        : DEFAULT_FULL_WIDTH_STEPS)),
  ]

  const retinaSizes = Array.from(
    // De-duplicate sizes with a Set
    new Set([
      ...baseSizes,
			...baseSizes.map((size) => size * 2),
    ])
  )
    .sort((a, b) => a - b) // Lowest to highest
    .filter(
      (size) =>
        // Exclude sizes 10% or more larger than the image itself. Sizes slightly larger
        // than the image are included to ensure we always get closest to the highest
        // quality for an image. Sanity's CDN won't scale the image above its limits.
        size <= imageDimensions.width * 1.1 &&
        // Exclude those larger than maxWidth's retina (x3)
        size <= maxWidth * 2
    )

    // Exclude those with a value difference to their following size smaller than `minimumWidthStep`
    // This ensures we don't have too many srcSet variations, polluting the HTML
    .filter((size, i, arr) => {
      const nextSize = arr[i + 1]
      if (nextSize) {
        return nextSize / size > minimumWidthStep + 1
      }

      return true
    })

  return {
    // Use the original image as the `src` for the <img>
    src: builder.width(maxWidth).url(),

    // Build a `{URL} {SIZE}w, ...` string for the srcset
    srcSet: retinaSizes
      .map((size) => `${builder.width(size).quality(75).url()} ${size || 500}w`)
      .join(', '),
    sizes:
      maxWidth === '100vw'
        ? '100vw'
        : sizes || `(max-width: ${maxWidth}px) 100vw, ${maxWidth}px`,

    // Let's also tell the browser what's the size of the image so it can calculate aspect ratios
    width: retinaSizes[0],
    height: Math.ceil(retinaSizes[0] / imageDimensions.aspectRatio),
  }
}