import React, { useEffect, useCallback } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion'

const Layout = ({ children, path, location }) => {
	const handleTab = useCallback((e) => {
		if (e.keyCode === 9) {
			if (typeof document !== `undefined`) {
				document.body.classList.add('kb-focus')
				window.removeEventListener('keydown', handleTab)
			}
		}
	}, [])

	useEffect(() => {
		window.addEventListener('keydown', handleTab)
		return () => {
			window.removeEventListener('keydown', handleTab)
		}
	}, [handleTab])

	useEffect(() => {
		if (location.hash) {

			const getLocation = () => {
				const scrollLocation = document.querySelector(location.hash)
				scrollLocation.scrollIntoView({ behavior: 'auto' }, true)
			}

			window.setTimeout(() => getLocation(), 750)
		}
	}, [location])

	return (
		<>
			<Header />
			<AnimatePresence mode='wait'>
				<motion.main
					className="flex-1 font-sans font-normal antialiased"
					key={location.key}
					transition={{ duration: 0.5 }}
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
				>
					{children}
					<Footer />
				</motion.main>
			</AnimatePresence>
		</>
	)
}

export default Layout
