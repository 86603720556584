import React, { useState } from "react"
import { Modal } from "./Modal"
import { SignUpForm } from './SignUpForm'
import { ContactForm } from './ContactForm'
import { PersonModal } from "./PersonModal"

const ModalButton = ({ type, content, setState, location }) => {
	const [visible, setVisible] = useState(false)
	if (type === 'team' && !content) return null

	const signUpStyles = {
		dropdown: 'h5',
		footer: 'h6 absolute right-5 -bottom-7 self-start -mt-3 md:relative md:inset-auto',
		header: 'h6'
	}

	const types = {
		signUp: {
			style:`bg-secondary round whitespace-nowrap z-10 w-24 h-12 lg:w-28 lg:h-14 lg:pt-1 ${signUpStyles[location]}`,
			name: 'SIGN UP',
			form: <SignUpForm hideModal={setVisible} setState={setState} />,
		},
		contact: {
			style: 'h6 ml-2 whitespace-nowrap md:ml-4 lg:ml-8',
			name: 'CONTACT US',
			form: <ContactForm hideModal={setVisible} />,
		},
		team: {
			style: 'h5 whitespace-nowrap',
			name: content?.name,
			form: <PersonModal {...content} hideModal={setVisible} />,
		}
	}

	const { style, name, form } = types[type];

	const handleClick = () => {
		setVisible(!visible)
		setState && setState(!visible)
	}

	return (
		<>
			<button
				className={style}
				arial-label="Open modal"
				aria-expanded={visible}
				onClick={handleClick}
			>
				{name}
			</button>
			<Modal visible={visible} toggleModal={setVisible} type={type} setState={setState}>
				{form}
			</Modal>
		</>
	);
}

export default ModalButton;
