import { useState, useEffect } from 'react'
import { useWindowSize, useMedia } from 'react-use'

export const useImageSize = (desktopPercentage, mobilePercentage, breakpoint='md') => {

	const breakpoints = {
		md: 768,
		lg: 1024,
		xl: 1253
	}

	const { width } = useWindowSize()
	const minWidth = breakpoints[breakpoint]
	const isWide = useMedia(`(min-width: ${minWidth}px)`)
	const [imageWidth, setImageWidth] = useState(breakpoints[breakpoint])

	useEffect(() => {
		if (width) {
			setImageWidth(
				isWide ?
				(width * desktopPercentage) :
				mobilePercentage ? (width * mobilePercentage) : width)
			}
		}, [width, isWide, desktopPercentage, mobilePercentage])

	return Math.ceil(imageWidth)
}
