import React, { useState, useRef } from "react"
import getImageProps from '../utils/image'
import { useInView } from 'framer-motion/dist/framer-motion'
import cx from 'clsx'

const SanityImage = (props) => {
	const { image, loading, className, maxWidth, viewportFadeIn } = props
	const viewRef = useRef(null)
	const inView = useInView(viewRef, { amount: 0.25, once: true })
	const [loaded, setLoaded] = useState(false)
	const { src, srcSet, sizes, width, height } = getImageProps({
		image,
		maxWidth
	});

	return (
		<img
			ref={viewRef}
			className={cx(
				className,
        loaded && !viewportFadeIn ? 'fade-in' : 'opacity-0',
				viewportFadeIn && inView ? 'fade-in--long' : 'opacity-0'
			)}
			alt={image?.alt ? image.alt : ' '}
			onLoad={() => setLoaded(true)}
			loading={loading}
			fetchpriority={loading === "eager" ? "high" : undefined}
			rel={loading === "eager" ? "preload" : undefined}
			src={src}
			srcSet={srcSet}
			sizes={sizes}
			width={width}
			height={height}
		/>
	)
}

export default SanityImage;
