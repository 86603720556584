import React, { useRef, useState, useLayoutEffect } from 'react'
import cx from 'clsx'
import Headroom from 'react-headroom'
import { useWindowScroll, useWindowSize, useLockBodyScroll } from 'react-use'
import { Link } from 'gatsby'
import { useScrollContext } from '../context/ScrollContext'
import { useLocation } from '@reach/router'
import { Emblem, Logo, NavLine, } from './Icons'
import { MenuButton } from './Menubutton'
import ModalButton from './ModalButton'

const Header = () => {
	const { data } = useScrollContext()
	const { pathname } = useLocation()
	const isHome = pathname === '/'
	const [menuOpen, setMenuOpen] = useState(false)
	const [hideMenu, setHideMenu] = useState(false)
	const [navHeight, setNavHeight] = useState(0)
	const ref = useRef(null)
	const navRef = useRef(null)
	const { y } = useWindowScroll()
	const { height, width } = useWindowSize()
	const threshold = y <= navHeight
	const isTop = threshold
	useLockBodyScroll(menuOpen)

	const firstListItems = [
		{ title: 'Our Cheese', id: 'cheese', path: '/', strokeWidth: 14 },
		{ title: 'How we do it', id: 'how', path: '/how', strokeWidth: 12 },
	]

	const secondListItems = [
		{ title: 'News', id: 'news', path: '/news', strokeWidth: 28 },
		{ title: 'Careers', id: 'careers', path: '/careers', strokeWidth: 20 },
	]

	const mobileListItems = [
		...firstListItems,
		...secondListItems,
	]

	const headroomWrapperStyle = {
		position: 'absolute',
		width: '100%',
		zIndex: data ? -1 : 100,
	}

	useLayoutEffect(() => {
		if (navRef && navRef.current) {
			setNavHeight(navRef.current.clientHeight)
		}
	}, [navRef, height, width])


	return (
		<>
			<Headroom
				ref={ref}
				wrapperStyle={headroomWrapperStyle}
				className={cx(
					data ? 'relative' : 'fixed'
				)}
			>
				<nav
					ref={navRef}
					aria-label='Main navigation'
					className={cx(
						'nav',
						data ? 'custom-unpin' : 'custom-pin',
					)}
					style={{
						backgroundColor: isTop ? 'transparent' : 'white',
						transition: 'all 0.5s ease'
					}}
				>
					<div className='menu-list'>
						<ul className='flex items-center basis-1/3 justify-between 2xl:mr-[4%]'>
							{firstListItems.map(item => (
								<li
									key={item.id}
									className='px-2 flex flex-col nav-link relative'
								>
									<Link
										to={item.path}
										title={item.title}
										activeClassName='active'
										className='block py-2 whitespace-nowrap'
									>
										{item.title}
									</Link>
									<div className='absolute w-full -bottom-3 left-0'>
										<NavLine strokeWidth={item.strokeWidth} />
									</div>
								</li>
							))}
						</ul>

						<Link
							to='/'
							title='Go back home'
							className='basis-1/3'
						>
							<div
								className='mx-auto'
								style={{
									width: isTop ? '18vw' : '14vw',
									transition: 'all 0.15s ease'
								}}
							>
								<Logo className='p-4'
								/>
							</div>
						</Link>

						<ul className='flex items-center basis-1/3 justify-between 2xl:ml-[4%]'>
							{secondListItems.map(item => (
								<li
									key={item.id}
									className='px-2 flex flex-col nav-link relative'
								>
									<Link
										to={item.path}
										title={item.title}
										activeClassName='active'
										className='block py-2'
									>
										{item.title}
									</Link>
									<div className='absolute w-full -bottom-2 left-0'>
										<NavLine strokeWidth={item.strokeWidth} />
									</div>
								</li>
							))}
							<ModalButton
								className=''
								location={'header'}
								type={'signUp'}
							/>
						</ul>
					</div>
				</nav>

				<div
					className={cx(
						hideMenu && 'invisible',
						!menuOpen && 'top-0 inset-x-0 pb-4 md:hidden',
						data ? 'custom-unpin' : 'custom-pin',
					)} >
					<div
						style={{
							width: isHome && isTop ? '224px' : '160px',
							transition: 'all 0.25s ease'
						}}
						className={cx(
							'mx-auto pt-4',
							menuOpen && 'hidden'
						)}>
						<Link
							to='/'
							title='Go back home'
							className=''
						>
							<Logo />
						</Link>
					</div>
					<div
						style={{
							height:
								!isHome ?
									menuOpen ? '80px' : '94px' :
									isTop || menuOpen ? '80px' : '94px',
							transition: 'all 0.1s'
						}}
						className='absolute top-0 w-full -z-10 bg-white'
					/>
					<div
						className='self-start'
					>
						<MenuButton
							width={40}
							height={15}
							isOpen={menuOpen}
							color='black'
							menuClicked={() => setMenuOpen(!menuOpen)}
						/>
					</div>
				</div>
			</Headroom>

			{menuOpen && (
				<menu
					className={cx(
						hideMenu && 'invisible',
						'fixed inset-0 z-40 md:hidden',
					)}
				>
					<div className='flex flex-col bg-primary overflow-y-auto h-full items-center justify-between'>
						<div className='bg-transparent w-full min-h-[96px] border-b-4' />
						<Emblem className='min-h-[150px] max-h-60 mt-8' />
						<ul className='text-black flex flex-col justify-center text-center my-6'>
							{mobileListItems.map((item, index) => (
								<button
									key={index}
									aria-label={`open ${item.title}`}
									onClick={() => setMenuOpen(false)}
									onKeyDown={() => setMenuOpen(false)}
									tabIndex={0}
								>
									<Link
										to={item.path}
										title={item.title}
									>
										<h3 className='h3 mb-6'>{item.title}</h3>
									</Link>
								</button>
							))}
						</ul>
						<div className='flex relative justify-center bg-transparent border-t-4 w-full '>
							<div className='absolute -top-8 mx-auto'>
								<ModalButton
									type={'signUp'}
									setState={setHideMenu}
									location={'dropdown'}
								/>
							</div>
							<div className='bg-white min-h-[50px] w-full mt-3' />
						</div>
					</div>
				</menu>
			)}
		</>
	)
}

export default Header
