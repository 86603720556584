import React, { useState } from 'react'
import { useForms } from '../hooks/useForms'
import { SignUpButton, Close } from './Icons'

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export const SignUpForm = ({ hideModal, setState }) => {
  const { categories } = useForms()
  const [value, setValue] = useState({})
  const [success, setSuccess] = useState(false)

  const handleChange = e => {
    setValue({ ...value, [e.target.name]: e.target.value })
  }

  const handleClose = e => {
    e.preventDefault()
    setState && setState(false)
    hideModal()
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...value,
      }),
    })
      .then(() => {
        form.reset()
        setSuccess(true)
      })
      .catch(error => alert(error))
  }

  return (
    <div className="p-5 bg-secondary h-full md:min-h-full rounded-full">
      <div className="h-full relative border-black border-4 xl:border-8 rounded-full">
        {!success ? (
          <>
            <form
              name="signUp"
              method="POST"
              action="/"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
              className="signup-form"
            >
              <button
                className="absolute top-[4vw] right-[5vw] md:right-[5vw] md:top-[1vw] cursor-pointer z-50"
                aria-label="Close Modal"
                onClick={handleClose}
              >
                <Close className="w-12 md:w-16" />
              </button>
              <input type="hidden" name="form-name" value="signUp" />
              <label className="hidden">
                Don't fill this out
                <input name="bot-field" onChange={handleChange} />
              </label>
              <h2 className="signup-header">Subscribe for Updates</h2>
              <div className="flex flex-col pt-10 md:py-0 md:w-[60%] md:mx-auto">
                <div className="flex flex-col md:flex-row md:justify-between">
                  <input
                    type="text"
                    name="firstName"
                    autoComplete="off"
                    onChange={handleChange}
                    className="body mb-5 border-2 md:border-[3px] py-[1.2%] focus-field bg-transparent text-center md:w-[48%]"
                    placeholder="First Name"
                    required
                  />
                  <input
                    type="text"
                    name="lastName"
                    autoComplete="off"
                    onChange={handleChange}
                    className="body mb-5 border-2 md:border-[3px] py-[1.2%] focus-field bg-transparent text-center md:w-[48%]"
                    placeholder="Last Name"
                    required
                  />
                </div>
                <input
                  type="email"
                  name="email"
                  required
                  placeholder="Your Email"
                  onChange={handleChange}
                  className="body mb-5 border-2 md:border-[3px] py-[1.2%] focus-field bg-transparent text-center"
                  autoComplete="off"
                />
                <select
                  name="category"
                  required
                  onBlur={handleChange}
                  className="signup-select"
                >
                  <option value="" defaultValue className="">
                    Which best describes you?
                  </option>
                  {categories?.map((item, index) => (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
              <button
                className="relative flex flex-col items-center self-center sm:pt-2"
                type="submit"
              >
                <SignUpButton className="absolute w-[140%] top-0 h-auto" />
                <p className="h2-alt">Subscribe</p>
              </button>
            </form>
          </>
        ) : (
          <div className="h-full flex justify-center items-center px-10 min-h-[620px] md:min-h-0 md:my-[8%]">
            <button
              className="absolute top-[4vw] right-[5vw] md:right-[4vw] md:top-[1vw] cursor-pointer z-50"
              aria-label="Close Modal"
              onClick={handleClose}
            >
              <Close className="w-12 md:w-16" />
            </button>
            <h2 className="h1-alt w-3/4 leading-relaxed text-center">
              Welcome to New Culture!
            </h2>
          </div>
        )}
      </div>
    </div>
  )
}
