import React from 'react'
import { ScrollContextProvider } from './src/context/ScrollContext'
import { AnimatePresence } from 'framer-motion/dist/framer-motion'
import './src/styles/main.css'

import smoothscroll from 'smoothscroll-polyfill'
smoothscroll.polyfill()

export const wrapRootElement = ({ element }) => (
	<ScrollContextProvider>{element}</ScrollContextProvider>
)

export const wrapPageElement = ({ element }) => (
	<AnimatePresence mode='wait'>{element}</AnimatePresence>
)

export const shouldUpdateScroll = ({
	routerProps: { location },
}) => {
	if (location.action === 'PUSH') {
		window.setTimeout(() => window.scrollTo(0, 0), 500)
	}
	return false
}