import React from 'react'

export const MenuButton = ({
	width = 24,
	height = 24,
	color,
	strokeWidth = 3,
	halfStrokeWidth = -strokeWidth / 2,
	halfHeight = height / 2,
	animationDuration = 0.25,
	isOpen = false,
	menuClicked,
}) => {
	const getTransformValue = (isOpen, defaultPos, rotateVal) => `
		translate3d(0, ${isOpen ? `${halfHeight}px` : `${defaultPos}px`}, 0)
		rotate(${isOpen ? `${rotateVal}deg` : '0'})
		scaleX(${isOpen ? `${0.75}` : '1'})
		`

	const styles = {
		container: {
			position: 'absolute',
			right: '30px',
			top: '30px',
			display: 'flex',
			width,
			height,
		},
		lineBase: {
			position: 'absolute',
			display: 'block',
			width: '100%',
			height: `${strokeWidth}px`,
			backgroundColor: `${color}`,
			transitionProperty: 'transform',
			transitionTimingFunction: 'ease',
			transitionDuration: `${animationDuration}s`,
			transformOrigin: 'center',
		},
		firstLine: {
			transform: getTransformValue(isOpen, 0, 45),
			marginTop: halfStrokeWidth,
		},
		circle: {
			position: 'absolute',
			border: 'black solid 3px',
			height: '50px',
			width: '50px',
			borderRadius: '50%',
			top: '-17px',
			right: '-5px',
		},
		hidden: {
			transition: `opacity ${animationDuration / 2}s`,
			opacity: 0,
		},
		visible: {
			transition: `opacity ${animationDuration / 2}s`,
			opacity: 1,
		},
		thirdLine: {
			transform: getTransformValue(isOpen, height, -45),
			marginTop: halfStrokeWidth,
		},
	}

	return (
		<button
			title="Open Menu"
			aria-label={!isOpen ? 'Open menu' : 'Close menu'}
			aria-expanded={isOpen}
			tabIndex={0}
			style={styles.container}
			onClick={menuClicked}
		>
			<span style={Object.assign({}, styles.lineBase, styles.firstLine)} />
			<span style={isOpen ? Object.assign({}, styles.circle, styles.visible) : Object.assign({}, styles.circle, styles.hidden)} />
			<span style={Object.assign({}, styles.lineBase, styles.thirdLine)} />
		</button>
	)
}
