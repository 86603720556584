import React, { createContext, useContext, useState } from 'react'

const ScrollContext = createContext(false)

export const ScrollContextProvider = ({ children }) => {
  const [data, setData] = useState(false)
  const updateState = (_data) => setData(_data)

  const contextValues = {
    data,
    updateState,
  }

  return <ScrollContext.Provider value={contextValues}>{children}</ScrollContext.Provider>
}

export const useScrollContext = () => {
  const context = useContext(ScrollContext)
  if (context === undefined || context === null) {
    throw new Error(`useScrollContext must be called within ScrollContextProvider`)
  }
  return context
}