import React, { useRef } from 'react'
import cx from 'clsx'
import FocusLock from 'react-focus-lock'
import { Portal } from 'react-portal'
import { useKey, useClickAway } from 'react-use'

export const Modal = ({ visible, toggleModal, children, type, setState }) => {
	const modalRef = useRef(null)

	useKey('Escape', () => handleClick(false))
	useClickAway(modalRef, () => handleClick(false))

	const handleClick = () => {
		toggleModal(false)
		setState && setState(false)
	}

	return (
		<>
			<div className='invisible absolute overflow-x-hidden w-0 h-0'>
				{children}
			</div>
			{visible && (
				<Portal>
					<FocusLock>
						<div className='fixed inset-0 z-max flex items-center justify-center'>
							<div
								ref={modalRef}
								className={cx(
									'max-h-screen mx-[10%] md:max-w-7xl md:h-auto',
									type === 'team' ? 'min-w-screen mx-0' : 'w-4/5',
									type === 'contact' && 'shadow-xl',
									type === 'signUp' && 'rounded-full'
								)}
							>
								{children}
							</div>
						</div>
					</FocusLock>
				</Portal>
			)}
		</>
	)
}
