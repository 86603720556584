import { graphql, useStaticQuery } from 'gatsby'

export const useSocial = () => {
  const data = useStaticQuery(graphql`
    query {
      social: sanitySocial {
        socialLinks {
          _key
          blank
          link
          title
        }
      }
    }
  `)

  const { social } = data || {}
  return social
}
